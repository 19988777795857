/* global.css */

/* Styles for RequestList component */
.request-list-container {
  font-family: Arial, sans-serif;
  margin: 1vw;
}

.request-list-container h2 {
  color: #333;
}

.request-list-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0 !important;
}

.request-list-container li {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.request-list-container li:hover {
  background-color: #f1f1f1;
}

.request-list-container button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.request-list-container button.accept {
  background-color: #4caf50;
  color: white;
}

.request-list-container button.reject {
  background-color: #f44336;
  color: white;
}

.request-list-container button.accept:hover {
  background-color: #45a049;
}

.request-list-container button.reject:hover {
  background-color: #e53935;
}

/* Styles for RequestForm component */
.request-form-container {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.request-form-container div {
  margin-bottom: 15px;
}

.request-form-container label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.request-form-container input {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.request-form-container input:focus {
  border-color: #4caf50;
  outline: none;
}

.request-form-container button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.request-form-container button:hover {
  background-color: #45a049;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 2rem 0;
  gap: var(2rem);
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.buttonContainerRow {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
}

.buttonAPI {
  margin-left: 10px;
  padding: 5px 10px;
  border: 1px solid slateblue;
  border-radius: 3px;
  cursor: pointer;
  background-color: #f1f1f1;
  width: 20vw;
  transition: all ease 0.1s;
}
.buttonAPI:hover {
  background-color: slateblue;
  color: aqua;
}
